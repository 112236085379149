<template>
  <v-simple-table class="pt-4">
    <template v-if="mode == 'Order'" v-slot:default>
      <thead>
        <tr>
          <th class="text-center display-1 font-weight-bold" colspan="9">
            {{ title }}
          </th>
        </tr>

        <tr>
          <th class="text-center">
            Month
          </th>
          <th class="text-center"></th>
          <th class="text-center">
            Sale Qty
          </th>
          <th class="text-center">
            Shipped
          </th>
          <th class="text-center">
            Unshipped
          </th>
          <th class="text-center">
            Complaint
          </th>
          <th class="text-center">
            Received Payment
          </th>
          <th class="text-center">
            Unpayed
          </th>
        </tr>
      </thead>

      <tbody>
        <tr class="text-center">
          <td rowspan="3">
            {{ datas.Month }}
          </td>
          <td>
            Total Sale
          </td>
          <td>
            {{ formatNumber(datas.SaleObj.saleSale) }}
          </td>
          <td>
            {{ formatNumber(datas.SaleObj.shippedSale) }}
          </td>
          <td>
            {{ formatNumber(datas.SaleObj.unshippedSale) }}
          </td>
          <td>
            {{ formatNumber(datas.SaleObj.complaintSale) }}
          </td>
          <td rowspan="3">
            {{ formatNumber(datas.SaleObj.receivedpaymentSale) }}
          </td>
          <td rowspan="3">
            {{ formatNumber(datas.SaleObj.unpaySale) }}
          </td>
        </tr>

        <tr class="text-center">
          <td>
            Total Order
          </td>
          <td>
            {{ formatNumber(datas.Orderobj.saleOrder) }}
          </td>
          <td>
            {{ formatNumber(datas.Orderobj.shippedOrder) }}
          </td>
          <td>
            {{ formatNumber(datas.Orderobj.unshippedOrder) }}
          </td>
          <td>
            {{ formatNumber(datas.Orderobj.complaintOrder) }}
          </td>
        </tr>

        <tr class="text-center">
          <td>
            Total Amount
          </td>
          <td>
            {{ formatNumber(datas.AmountObj.saleAmount) }}
          </td>
          <td>
            {{ formatNumber(datas.AmountObj.shippedAmount) }}
          </td>
          <td>
            {{ formatNumber(datas.AmountObj.unshippedAmount) }}
          </td>
          <td>
            {{ formatNumber(datas.AmountObj.complaintAmount) }}
          </td>
        </tr>
      </tbody>
    </template>

    <template v-else v-slot:default>
      <thead>
        <tr>
          <th class="text-center display-1 font-weight-bold" colspan="4">
            {{ title }}
          </th>
        </tr>

        <tr>
          <th class="text-center">
            Month
          </th>
          <th class="text-center">
            Territory
          </th>
          <th class="text-center">
            Abroad
          </th>
          <th class="text-center">
            Total
          </th>
        </tr>
      </thead>

      <tbody>
        <tr class="text-center">
          <td>
            {{ datas.Month }}
          </td>
          <td>
            {{ datas.territory }}
          </td>
          <td>
            {{ datas.abroad }}
          </td>
          <td>
            {{ datas.total }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "StatisticalTableOrder",
  props: { datas: Object, mode: String, title: String }
};
</script>

<style lang="sass">
tr th
  border-bottom: 0.5px solid lightgrey

tbody
  tr:hover
    background-color: transparent !important
</style>
